import { useT } from "src/components/hoc/withTranslation";
import NavItem from "src/components/layout/layout-default/sidebar/NavItem";
import SupportButton, {
	SupportButtonContent,
	SupportButtonItem,
	SupportButtonItemDescription,
	SupportButtonItemIcon,
	SupportButtonItemLabel,
	SupportButtonTrigger,
} from "src/components/layout/support-button";
import useWindowSize from "src/hooks/useWindowSize";

export default function NavSupportButton() {
	const { isPhone } = useWindowSize();
	const t = useT({
		nl: {
			feedback: "Support",
		},
		en: {
			feedback: "Support",
		},
	});
	return (
		<SupportButton>
			<SupportButtonTrigger>
				<div>
					<NavItem icon="fa-comment-alt" className="cursor-pointer">
						{t("feedback")}
					</NavItem>
				</div>
			</SupportButtonTrigger>
			<SupportButtonContent
				side={isPhone ? "bottom" : "right"}
				sideOffset={12}
				align={isPhone ? "start" : undefined}
				className="flex flex-col gap-2 p-1 max-w-full md:max-w-[200px] md:mb-2"
			>
				<SupportButtonItem type="whatsapp">
					<SupportButtonItemIcon />
					<div className="flex flex-col gap-1">
						<SupportButtonItemLabel />
						<SupportButtonItemDescription />
					</div>
				</SupportButtonItem>
				<SupportButtonItem type="email">
					<SupportButtonItemIcon />
					<div className="flex flex-col gap-1">
						<SupportButtonItemLabel />
						<SupportButtonItemDescription />
					</div>
				</SupportButtonItem>
			</SupportButtonContent>
		</SupportButton>
	);
}
