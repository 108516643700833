import { useEffect, useState } from "react";
import { ApiListTenant } from "src/api/types";
import Button, { ButtonProps } from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Tenant, {
	TenantDescription,
	TenantLogo,
	TenantName,
} from "src/components/tenant/tenant";
import useTenants from "src/hooks/api/services/tenants/useTenants";
import useAlert from "src/hooks/useAlert";
import { cn } from "src/lib/utils";

interface OurTenantsCardProps extends Translation {
	className?: string;
	layout?: "stacked" | "horizontal";
	hideTitle?: boolean;
}

const OurTenantsCard = ({
	t,
	className,
	layout = "stacked",
	hideTitle = false,
}: OurTenantsCardProps) => {
	const { tenants, actions } = useTenants();

	useEffect(() => {
		if (!tenants.length) {
			actions.list();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Card
			title={!hideTitle ? t("title") : undefined}
			className={cn(className)}
		>
			<div
				className={cn(
					"flex flex-col gap-6",
					layout === "horizontal" && "md:flex-row"
				)}
			>
				{tenants.map((tenant) => (
					<Tenant id={tenant.slug}>
						<div className="flex flex-col flex-1">
							<div className="flex items-center gap-4">
								<TenantLogo
									variant="small"
									color="primary"
									className="w-8"
								/>
								<div className="flex flex-col flex-1">
									<TenantName />
									<TenantDescription className="opacity-70" />
								</div>
								<JoinTenantButton {...{ tenant }}>
									{t("join")}
								</JoinTenantButton>
							</div>
						</div>
					</Tenant>
				))}
			</div>
		</Card>
	);
};

OurTenantsCard.locale = {
	nl: {
		title: "Onze platformen",
	},
	en: {
		title: "Our platforms",
	},
};

interface JoinTenantButtonProps extends ButtonProps, Translation {
	tenant: ApiListTenant;
}

const JoinTenantButton = withTranslation(
	({ t, tenant, children, ...props }: JoinTenantButtonProps) => {
		const [isLoading, setIsLoading] = useState(false);
		const [sendAlert] = useAlert();
		const { actions } = useTenants();
		const onClick = () => {
			if (tenant.has_access) {
				return;
			}
			sendAlert({
				type: "confirm",
				title: t("confirm.title"),
				text: t("confirm.text", {
					name: tenant.name,
				}),
				onConfirm: async () => {
					setIsLoading(true);
					try {
						const data = await actions.join(
							tenant,
							"FROM_OUR_PRODUCTS"
						);
						window.location = data.redirect_url;
					} finally {
						setIsLoading(false);
					}
				},
			});
		};
		return (
			<div data-theme={tenant.slug}>
				<Button
					type="primary"
					xsmall
					{...props}
					disabled={tenant.has_access}
					{...{ onClick }}
					loading={isLoading}
				>
					{tenant.has_access ? (
						<i className="far fa-check"></i>
					) : (
						t("join")
					)}
				</Button>
			</div>
		);
	},
	{
		nl: {
			join: "Join",
			joined: "Al ingeschakeld",
			confirm: {
				title: "Wil je je account inschakelen?",
				text: "Wanneer je op bevestigen klikt, wordt je account bij {{name}} ingeschakeld en word je doorverwezen.",
			},
		},
		en: {
			join: "Join",
			joined: "Already enabled",
			confirm: {
				title: "Do you want to activate your account?",
				text: "When you click confirm, your account will be activated and you will be redirected.",
			},
		},
	}
);

export default withTranslation(OurTenantsCard);
