import { ComponentProps, useMemo } from "react";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import BoardNavItems from "src/components/layout/layout-default/sidebar/BoardNavItems";
import NavItem from "src/components/layout/layout-default/sidebar/NavItem";
import NavItemGroup from "src/components/layout/layout-default/sidebar/NavItemGroup";
import NavSupportButton from "src/components/layout/layout-default/sidebar/NavSupportButton";
import NavTenantSwitcher from "src/components/layout/layout-default/sidebar/NavTenantSwitcher";
import NavbarToggle from "src/components/layout/NavbarToggle";
import SubscribeExternCard from "src/components/promotion/cards/SubscribeExternCard";
import SubscribeInternCard from "src/components/promotion/cards/SubscribeInternCard";
import usePermissions from "src/hooks/api/services/auth/usePermissions";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import useSubscription from "src/hooks/selectors/useSubscription";
import useLayout from "src/hooks/useLayout";
import { cn } from "src/lib/utils";

const NavItems = ({ t }: Translation) => {
	const auth = useAuth();
	const {
		tenant: { slug, modules, urls, settings },
	} = useTenant();
	const subscription = useSubscription();
	const permissions = usePermissions();
	const searchTasksUrl = useMemo(() => {
		if (slug === "getcrewdone") {
			return urls.public + "/evenementen";
		}
		if (["getrecruitmentdone", "getstagedone"].includes(slug)) {
			return urls.community + "/vacatures?ref=account";
		}

		if (urls.community) {
			return urls.community + "/opdrachten?ref=account";
		}

		return urls.public + "/opdrachten/alles/overal";
	}, [slug, urls]);

	if (
		auth.type === "student" &&
		!modules.includes("intern-task") &&
		modules.includes("extern-task")
	) {
		return (
			<>
				<Wrapper className="flex-1 gap-6 md:gap-8">
					<NavTenantSwitcher />
					<NavItemGroup
						name="general"
						label={t("groups.general")}
						variant="stacked"
					>
						<NavItem icon="fas fa-user" to="/dashboard">
							{t("my-profile")}
						</NavItem>
						{settings.benefits.enabled && (
							<NavItem
								icon="fas fa-badge-dollar"
								to="/benefits"
								isNew
							>
								{t("benefits")}
							</NavItem>
						)}
					</NavItemGroup>

					<NavItemGroup
						name="tasks"
						label={t("groups.tasks")}
						variant="stacked"
					>
						{permissions.has("registrations.create") && (
							<NavItem
								disabled={!auth.is_verified}
								help={
									!auth.is_verified
										? t("help.search_tasks")
										: undefined
								}
								to={searchTasksUrl}
								icon="fas fa-search"
								showExternalIcon
							>
								{t("search-new-tasks")}
							</NavItem>
						)}
						<NavItem icon="fas fa-check-circle" to="/tasks">
							{t("my-tasks")}
						</NavItem>
						{modules.includes("talentboard") && (
							<NavItem
								icon="fas fa-list"
								to="/offers"
								count={auth?.unreads?.offers}
							>
								{t("my-offers")}
							</NavItem>
						)}
					</NavItemGroup>

					<NavItemGroup
						name="network"
						label={t("groups.network")}
						variant="stacked"
					>
						<NavItem
							icon="fas fa-comments"
							to="/inbox"
							count={auth?.unreads?.messages}
						>
							{t("messages")}
						</NavItem>
						{modules.includes("student-pool") && (
							<>
								<NavItem icon="fas fa-users" to="/network">
									{t("my-network")}
								</NavItem>
								<NavItem
									icon="fas fa-envelope-open"
									to="/network/invites"
									count={auth?.unread_invites}
								>
									{t("invites")}
								</NavItem>
							</>
						)}
					</NavItemGroup>
					<NavItemGroup
						name="administration"
						label={t("groups.administration")}
						variant="stacked"
					>
						{modules.includes("payment-requests") && (
							<NavItem
								to="/payment-requests"
								icon="fas fa-alarm-clock"
								count={auth?.unreads?.payment_requests?.pending}
							>
								{t("payment-requests.full")}
							</NavItem>
						)}
						{modules.includes("hour-validation") && (
							<NavItem
								to="/hours"
								icon="fas fa-alarm-clock"
								count={auth?.unreads?.hours}
							>
								{t("my-hours")}
							</NavItem>
						)}

						<NavItem icon="fas fa-credit-card" to="/payments">
							{t("my-payments")}
						</NavItem>
						<NavItem
							icon="fas fa-file-signature"
							to="/envelopes"
							count={auth?.unreads?.envelopes}
						>
							{t("my-envelopes")}
						</NavItem>
					</NavItemGroup>
					<NavbarToggle
						forcedVariant="default"
						className="relative flex opacity-100 w-[34px] h-[34px] justify-center items-center border border-transparent hover:border-border rounded-md transition-all duration-300"
						containerClassName="opacity-100"
					/>
				</Wrapper>
				<Wrapper>
					<NavSupportButton />
				</Wrapper>
			</>
		);
	}

	if (auth.type === "student" && modules.includes("vacancies")) {
		return (
			<>
				<Wrapper className="flex-1 gap-6 md:gap-8">
					<NavTenantSwitcher />

					<NavItemGroup
						name="general"
						label={t("groups.general")}
						variant="stacked"
					>
						<NavItem icon="fas fa-user" to="/dashboard">
							{t("my-profile")}
						</NavItem>
						{settings.benefits.enabled && (
							<NavItem
								icon="fas fa-badge-dollar"
								to="/benefits"
								isNew
							>
								{t("benefits")}
							</NavItem>
						)}
					</NavItemGroup>

					<NavItemGroup
						name="tasks"
						label={t("groups.vacancies")}
						variant="stacked"
					>
						{permissions.has("registrations.create") && (
							<NavItem
								to={searchTasksUrl}
								icon="fas fa-search"
								showExternalIcon
							>
								{t("search-new-tasks")}
							</NavItem>
						)}
						<NavItem icon="fas fa-check-circle" to="/tasks">
							{t("my-tasks")}
						</NavItem>
					</NavItemGroup>

					<NavItemGroup
						name="network"
						label={t("groups.network")}
						variant="stacked"
					>
						<NavItem
							icon="fas fa-comments"
							to="/inbox"
							count={auth?.unreads?.messages}
						>
							{t("messages")}
						</NavItem>
					</NavItemGroup>
				</Wrapper>
				<Wrapper>
					<NavSupportButton />
				</Wrapper>
			</>
		);
	}

	return (
		<>
			<Wrapper
				className={cn(
					"flex-1",
					(modules.includes("extern-task") ||
						modules.includes("vacancies")) &&
						"gap-6 md:gap-8"
				)}
			>
				<NavTenantSwitcher />

				{modules.includes("intern-task") && (
					<NavItem to="/dashboard" icon="fa-home">
						{t("dashboard")}
					</NavItem>
				)}

				{modules.includes("intern-task") && auth.type === "company" && (
					<NavItem to="/projects" icon="fa-folder">
						{t("projects")}
					</NavItem>
				)}

				{modules.includes("intern-task") && (
					<hr className="border-border my-4 lg:hidden xl:flex" />
				)}

				{modules.includes("intern-task") ? (
					<BoardNavItems />
				) : (
					<NavItemGroup
						name="general"
						label={t("groups.general")}
						variant="stacked"
					>
						<NavItem to="/dashboard" icon="fa-home">
							{t("dashboard")}
						</NavItem>
						{modules.includes("extern-task") && (
							<NavItem
								icon="fa-check-circle"
								to="/tasks"
								activeStartsWith="/tasks"
							>
								{t("tasks")}
							</NavItem>
						)}
					</NavItemGroup>
				)}

				{modules.includes("vacancies") && (
					<NavItemGroup
						name="vacancies"
						label={t("groups.vacancies")}
						variant="stacked"
					>
						<NavItem
							to="/vacancies"
							icon="fa-file-alt"
							activeStartsWith="/vacancies"
						>
							{t("vacancies")}
						</NavItem>
					</NavItemGroup>
				)}

				{(modules.includes("extern-task") ||
					modules.includes("vacancies")) && (
					<>
						<NavItemGroup
							name="network"
							label={t("groups.network")}
							variant="stacked"
						>
							<NavItem
								to="/inbox"
								count={auth?.unreads?.messages}
								icon="fa-comments"
								activeStartsWith="/inbox"
							>
								{t("messages")}
							</NavItem>
							{modules.includes("student-pool") && (
								<NavItem
									to="/network"
									icon="fa-users"
									activeStartsWith="/network"
								>
									{t("studentpool")}
								</NavItem>
							)}
							{modules.includes("talentboard") &&
								auth.type === "company" &&
								auth?.company?.statuses
									?.has_offer_registrations && (
									<NavItem
										to="/talents"
										count={auth?.unreads?.offers}
										icon="fa-users-crown"
									>
										{t("talents")}
									</NavItem>
								)}
						</NavItemGroup>
					</>
				)}

				{modules.includes("payment-requests") && (
					<NavItemGroup
						name="payment-requests"
						label={t("groups.payment-requests")}
						variant="stacked"
					>
						<NavItem
							to="/payment-requests"
							icon="fas fa-alarm-clock"
							count={auth?.unreads?.payment_requests?.pending}
						>
							{t("payment-requests.all")}
						</NavItem>
						<NavItem
							to="/payment-requests/process"
							icon="fas fa-cash-register"
							count={auth?.unreads?.payment_requests?.approved}
						>
							{t("payment-requests.process")}
						</NavItem>
						<NavItem
							to="/payment-requests/finalize"
							icon="fas fa-check-circle"
							count={
								auth?.unreads?.payment_requests?.needs_review
							}
						>
							{t("payment-requests.complete")}
						</NavItem>
					</NavItemGroup>
				)}
				{modules.includes("vacancies") && (
					<div className="flex-1"></div>
				)}

				{(modules.includes("extern-task") ||
					modules.includes("vacancies")) && (
					<NavItemGroup
						name="administration"
						label={t("groups.administration")}
						variant="stacked"
					>
						{modules.includes("hour-validation") && (
							<NavItem
								to="/hours"
								icon="fa-alarm-clock"
								count={auth?.unreads?.hours}
							>
								{t("hours")}
							</NavItem>
						)}
						<NavItem
							to="/payments"
							count={auth?.unreads?.open_payments}
							icon="fa-credit-card"
						>
							{t("payments")}
						</NavItem>
						{modules.includes("extern-task") && (
							<NavItem
								to="/envelopes"
								count={auth?.unreads?.envelopes}
								icon="fa-file-signature"
							>
								{t("agreements")}
							</NavItem>
						)}
					</NavItemGroup>
				)}

				{slug === "getjobsdone" && (
					<NavItemGroup
						name="tools"
						label={t("section.tools")}
						variant="stacked"
					>
						<NavItem
							to="/tools/job-offer-converter"
							icon="fa-robot"
						>
							{t("tools.job-offer-converter")}
						</NavItem>
						<NavItem
							to="/tools/cost-calculator"
							icon="fa-calculator"
						>
							{t("tools.cost-calculator")}
						</NavItem>
					</NavItemGroup>
				)}
			</Wrapper>
			{!["getrecruitmentdone", "getstagedone"].includes(slug) && (
				<Wrapper>
					{!subscription?.id && (
						<>
							{modules.includes("extern-task") && (
								<SubscribeExternCard />
							)}

							{modules.includes("intern-task") &&
								!modules.includes("extern-task") && (
									<SubscribeInternCard />
								)}
						</>
					)}

					{permissions.has("analytics.view") &&
						modules.includes("analytics") && (
							<NavItem
								icon="fa-chart-line"
								to="/analytics/dashboard"
							>
								{t("analytics")}
							</NavItem>
						)}

					{slug === "odylyt" && (
						<NavItem
							icon="fas fa-question"
							to={`mailto:support@odylyt.com?body=${encodeURIComponent(
								`\n\nCode: ${auth.hid}\nOrigin: ${window.location.href}`
							)}`}
						>
							Support
						</NavItem>
					)}
					<NavSupportButton />
				</Wrapper>
			)}

			{["getrecruitmentdone", "getstagedone"].includes(slug) && (
				<Wrapper>
					<NavSupportButton />
				</Wrapper>
			)}
		</>
	);
};

const Wrapper = ({ children, ...rest }: ComponentProps<"div">) => {
	const { layout } = useLayout();
	return (
		<div
			className={cn(
				"flex flex-col p-4 gap-2",
				layout.navbar.variant === "default" &&
					"xl:min-w-[272px] w-full min-w-auto",
				rest?.className
			)}
		>
			{children}
		</div>
	);
};

NavItems.locale = {
	nl: {
		section: {
			tasks: "Taken",
			"my-boards": "Mijn takenborden",
			general: "Algemeen",
			tools: "Tools",
		},
		groups: {
			general: "Algemeen",
			tasks: "Opdrachten",
			network: "Netwerk",
			administration: "Administratie",
			"payment-requests": "Betaalverzoeken",
			vacancies: "Vacatures",
		},
		help: {
			search_tasks: "Maak je account compleet",
		},
		tools: {
			"cost-calculator": "Parttime kosten",
			"job-offer-converter": "Vacature omzetten",
		},
		"payment-requests": {
			full: "Betaalverzoeken",
			all: "Alle verzoeken",
			process: "Verwerken",
			complete: "Afronden",
		},
		vacancies: "Vacatures",
		invites: "Uitnodigingen",
		"search-new-tasks": "Zoeken",
		dashboard: "Mijn dashboard",
		messages: "Berichten",
		hours: "Uren controle",
		"my-hours": "Urenregistratie",
		studentpool: "Connecties",
		talents: "Professionals",
		payments: "Betalingen",
		agreements: "Overeenkomsten",
		"my-profile": "Mijn profiel",
		signout: "Uitloggen",
		tasks: "Opdrachten",
		"search-tasks": "Opdrachten zoeken",
		"my-tasks": "Aanmeldingen",
		"my-offers": "Aanbod",
		"my-network": "Netwerk",
		"my-payments": "Betalingen",
		"my-envelopes": "Overeenkomsten",
		links: "Links",
		faq: "Veelgestelde vragen",
		contact: "Contact",
		"edit-profile": "Profiel aanpassen",
		settings: "Instellingen",
		reviews: "Reviews",
		feedback: "Support",
		tutorial: "Tutorial",
		community: "Community",
		administration: "Administratie",
		invite: "Referrals",
		projects: "Projecten",
		analytics: "Analytics",
		benefits: "Voordelen",
	},
	en: {
		section: {
			tasks: "Tasks",
			"my-boards": "My task boards",
			general: "General",
			tools: "Tools",
		},
		groups: {
			general: "General",
			tasks: "Assignments",
			network: "Network",
			administration: "Administration",
			"payment-requests": "Payment requests",
			vacancies: "Vacancies",
		},
		"payment-requests": {
			all: "All",
			process: "Process",
			complete: "Finalize",
			completed: "Completed",
			approved: "Approved",
			rejected: "Rejected",
		},
		tools: {
			"cost-calculator": "Part-time costs",
			"job-offer-converter": "Convert job offers",
		},
		help: {
			search_tasks: "Complete your account",
		},
		vacancies: "Vacancies",
		invites: "Invitations",
		"search-new-tasks": "Search",
		dashboard: "My dashboard",
		messages: "Messages",
		hours: "Hour control",
		"my-hours": "Hour registration",
		studentpool: "Connections",
		talents: "Professionals",
		payments: "Payments",
		agreements: "Agreements",
		"my-profile": "My profile",
		signout: "Sign out",
		tasks: "Assignments",
		"search-tasks": "Search assignments",
		"my-tasks": "Registrations",
		"my-offers": "Offers",
		"my-network": "Network",
		"my-payments": "Payments",
		"my-envelopes": "Agreements",
		links: "Links",
		faq: "Frequently Asked Questions",
		contact: "Contact",
		"edit-profile": "Edit profile",
		settings: "Settings",
		reviews: "Reviews",
		feedback: "Feedback",
		tutorial: "Tutorial",
		community: "Community",
		administration: "Administration",
		invite: "Referrals",
		projects: "Projects",
		analytics: "Analytics",
		benefits: "Benefits",
	},
};

export default withTranslation(NavItems);
