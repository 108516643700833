import Button from "src/components/Button";
import FacebookButton from "src/components/FacebookButton";
import AgreeTermsCheckbox from "src/components/form/AgreeTermsCheckbox";
import Input from "src/components/form/Input";
import OAuthProviders from "src/components/forms/auth/OAuthProviders";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Line from "src/components/Line";
import useSignup from "src/hooks/api/services/auth/useSignup";
import useTenant from "src/hooks/api/services/tenants/useTenant";

export type SignupConnectionType = {
	id: string;
	type: "STUDENT" | "COMPANY";
	invite_id?: string;
};

interface SignupFormProps extends Translation {
	hideTerms?: boolean;
	defaultValue?: any;
	sessionId?: string;
	connection?: SignupConnectionType;
}

const SignupForm = ({
	t,
	hideTerms,
	defaultValue,
	sessionId,
	connection,
}: SignupFormProps) => {
	const { tenant } = useTenant();
	const { actions, form, validate, status } = useSignup({
		type: "later",
		defaultValue,
	});
	const hasMultiProviders = [
		"odylyt",
		"getjobsdone",
		"getrecruitmentdone",
		"getstagedone",
	].includes(tenant.slug);

	const getFormField = (name: string) => {
		return {
			...actions.getField(name),
			noMarginBottom: true,
			placeholder: t(`fields.${name}.placeholder`),
		};
	};

	const onSubmit = (e?: any) => {
		if (e) e.preventDefault();
		if (!validate.isValid) return;

		actions.signup({
			session_id: sessionId,
			connection_id: connection?.id,
			connection_type: connection?.type,
			invite_connection_id: connection?.invite_id,
		});
	};

	return (
		<div className="flex flex-col gap-4">
			{hasMultiProviders ? (
				<OAuthProviders {...{ connection }} />
			) : (
				<FacebookButton
					{...{ connection }}
					hideDisclaimer
					type="signup"
					params={{
						is_type_later: true,
					}}
				/>
			)}
			<Line label={t("or") || ""} />

			<form className="flex flex-col gap-4" {...{ onSubmit }}>
				<div className="flex gap-2">
					<Input {...getFormField("first_name")} />
					<Input {...getFormField("last_name")} />
				</div>
				<Input {...getFormField("email")} type="email" />
				<Input {...getFormField("password")} type="password" />
				{!hideTerms ? (
					<AgreeTermsCheckbox
						className="my-2"
						value={form.agreed}
						onChange={() =>
							actions.setForm({
								agreed: !form.agreed,
							})
						}
					/>
				) : (
					<div className="mb-2"></div>
				)}

				<Button
					loading={status !== "idle"}
					disabled={!form.agreed || !validate.isValid}
					submit
				>
					{t("buttons.signup")}
				</Button>
			</form>
		</div>
	);
};

SignupForm.locale = {
	nl: {
		or: "of via email",
		fields: {
			first_name: {
				placeholder: "Voornaam",
			},
			last_name: {
				placeholder: "Achternaam",
			},
			email: {
				placeholder: "Email",
			},
			telephone: {
				placeholder: "Telefoonnummer",
			},
			password: {
				placeholder: "Wachtwoord",
			},
		},

		buttons: {
			signup: "Aanmelden",
		},
	},
	en: {
		or: "via email",
		fields: {
			first_name: {
				placeholder: "Firstname",
			},
			last_name: {
				placeholder: "Lastname",
			},
			email: {
				placeholder: "Email",
			},
			telephone: {
				placeholder: "Telephone",
			},
			password: {
				placeholder: "Password",
			},
		},
		buttons: {
			signup: "Signup",
		},
	},
};

export default withTranslation(SignupForm);
