import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";

const StudentCanRegister = ({ t }: Translation) => {
	const auth = useAuth();
	const { tenant } = useTenant();
	let url = tenant?.urls?.public + "/opdrachten/alles/overal";

	if (tenant.urls.community) {
		url = tenant.urls.community + "/opdrachten";
	}

	if (tenant?.slug === "getcrewdone") {
		url = tenant?.urls?.public + "/evenementen";
	}

	if (
		tenant?.slug === "getrecruitmentdone" ||
		tenant?.slug === "getstagedone"
	) {
		url = tenant?.urls?.community + "/vacatures";
	}

	if (
		auth.onboarding_status === "verified" &&
		!auth.status.has_registrations
	) {
		return (
			<a
				target="_blank"
				href={url}
				className="p-4 flex gap-2 items-center rounded-md bg-green-200 text-green"
				rel="noreferrer"
			>
				<i className="fas fa-check"></i>
				<small className="flex-1 font-bold">
					{t(
						tenant.slug === "getrecruitmentdone"
							? "description-vacancies"
							: "description"
					)}
				</small>
				<i className="fas fa-arrow-right"></i>
			</a>
		);
	}
	return <></>;
};

StudentCanRegister.locale = {
	nl: {
		description:
			"Je account is goedgekeurd! Klik hier om op opdrachten te reageren",
		"description-vacancies":
			"Je account is goedgekeurd! Klik hier om op vacatures te reageren",
	},
	en: {
		description: "You can now register for jobs and events",
		"description-vacancies": "You can now register for job offers",
	},
};

export default withTranslation(StudentCanRegister);
