import { ComponentProps, createContext, useContext, useMemo } from "react";
import { useT } from "src/components/hoc/withTranslation";
import Link from "src/components/Link";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "src/components/ui/popover";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

const EMAIL_URLS = {
	getjobsdone: "https://getjobsdone.nl/feedback",
	getcrewdone: "https://getcrewdone.nl/contact",
	getrecruitmentdone: "https://getcrewdone.nl/contact",
	getstagedone: "https://getstagedone.nl/contact",
	default: "/feedback",
} as any;

const WHATSAPP_URL = {
	whatsapp:
		"https://api.whatsapp.com/send/?phone=310332002477&text&type=phone_number&app_absent=0",
};

interface SupportButtonProps {
	children: React.ReactNode;
}

export default function SupportButton({ children }: SupportButtonProps) {
	const { tenant } = useTenant();

	if (tenant.slug === "odylyt") {
		return null;
	}
	return <Popover>{children}</Popover>;
}

export const SupportButtonTrigger = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	return <PopoverTrigger asChild>{children}</PopoverTrigger>;
};

export const SupportButtonContent = (
	props: ComponentProps<typeof PopoverContent>
) => {
	return <PopoverContent {...props} />;
};

interface SupportButtonItemContextType {
	type: "whatsapp" | "email";
}

const SupportButtonItemContext = createContext<SupportButtonItemContextType>(
	{} as SupportButtonItemContextType
);

interface SupportButtonItemProps
	extends Omit<ComponentProps<typeof Link>, "to"> {
	type: SupportButtonItemContextType["type"];
}

export const SupportButtonItem = ({
	type,
	className,
	...props
}: SupportButtonItemProps) => {
	const { tenant } = useTenant();
	const { auth } = useAuth();
	const emailUrl = useMemo(() => {
		if (tenant.slug === "getrecruitmentdone") {
			return null;
		}
		let url = EMAIL_URLS[tenant.slug || "default"] || "";

		if (url.startsWith("https")) {
			const search = new URLSearchParams({
				email: auth?.email || "",
				fullname: auth?.full_name || "",
				"field[66]":
					auth?.type === "company"
						? "Opdrachtgever"
						: "Opdrachtnemer",
			});
			url += `?${search.toString()}`;
		}

		return url;
	}, [tenant.slug, auth]);
	return (
		<SupportButtonItemContext.Provider value={{ type }}>
			<Link
				{...props}
				target="_blank"
				className={cn(
					"p-2 rounded-md w-full transition-all duration-500 flex gap-2 items-center",
					type === "whatsapp" &&
						"hover:text-[#25d366] hover:bg-[#25d36620]",
					type === "email" && "hover:bg-accent",
					className
				)}
				to={type === "whatsapp" ? WHATSAPP_URL.whatsapp : emailUrl}
			/>
		</SupportButtonItemContext.Provider>
	);
};

export const SupportButtonItemIcon = ({
	className,
	...props
}: ComponentProps<"i">) => {
	const { type } = useContext(SupportButtonItemContext);
	return (
		<i
			className={cn(
				"text-[18px] leading-[100%]",
				type === "whatsapp" && "fab fa-whatsapp",
				type === "email" && "fas fa-envelope",
				className
			)}
			{...props}
		/>
	);
};

export const SupportButtonItemLabel = ({
	className,
	...props
}: ComponentProps<"span">) => {
	const { type } = useContext(SupportButtonItemContext);
	const t = useT({
		nl: {
			whatsapp: "WhatsApp",
			email: "Email",
		},
		en: {
			whatsapp: "WhatsApp",
			email: "Email",
		},
	});
	return (
		<span
			className={cn("font-regular text-[16px] leading-[100%]", className)}
			{...props}
		>
			{t(type)}
		</span>
	);
};

export const SupportButtonItemDescription = ({
	className,
	...props
}: ComponentProps<"span">) => {
	const { type } = useContext(SupportButtonItemContext);
	const t = useT({
		nl: {
			whatsapp: "Reactie binnen 24u",
			email: "Reactie binnen 72u",
		},
		en: {
			whatsapp: "Response within 24h",
			email: "Response within 72h",
		},
	});
	return (
		<span
			className={cn("opacity-70 text-[16px] leading-[100%]", className)}
			{...props}
		>
			{t(type)}
		</span>
	);
};
