import Button from "src/components/Button";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Layout from "src/components/layout/Layout";
import MiddlewareLayout from "src/components/middlewares/MiddlewareLayout";
import useTenant from "src/hooks/api/services/tenants/useTenant";

interface NoSubscriptionMiddlewareProps extends Translation {
	title?: string;
	children?: any;
	tabs?: any;
}

const NoSubscriptionMiddleware = ({
	t,
	children,
	tabs,
	title,
}: NoSubscriptionMiddlewareProps) => {
	const { tenant } = useTenant();

	if (tenant.slug === "getjobsdone") {
		return (
			<Layout>
				<div className="flex flex-col flex-1 justify-center items-center gap-12 max-w-[1080px] mx-auto">
					<div className="flex gap-12 flex-col md:flex-row items-center">
						<div className="flex flex-col gap-2 flex-1 text-center md:text-left">
							<h1 className="text-2xl md:text-3xl">
								{t("getjobsdone.title")}
							</h1>
							<p className="md:text-[20px]">
								{t("getjobsdone.description")}
							</p>
						</div>
						<div className="flex flex-col flex-1 gap-4">
							{["first", "second", "third"].map((usp) => (
								<div
									key={usp}
									className="flex gap-2 items-center"
								>
									<i className="fas fa-check text-primary md:text-[20px]"></i>
									<p className="md:text-[20px] flex-1">
										{t(`getjobsdone.usps.${usp}`)}
									</p>
								</div>
							))}
						</div>
					</div>
					<Button to="/subscription">
						{t("getjobsdone.button")}
					</Button>
				</div>
			</Layout>
		);
	}

	return (
		<MiddlewareLayout
			title={title || ""}
			{...{ tabs }}
			heading={t("title")}
		>
			{children}

			<div className="flex flex-col">
				<p className="max-w-md text-center">{t("description")}</p>
			</div>
		</MiddlewareLayout>
	);
};

NoSubscriptionMiddleware.locale = {
	nl: {
		title: "Upgrade je abonnement",
		description:
			"Om gebruik te kunnen maken van {{tenant.name}} heb je een actief abonnement nodig. Dit kan je eenvoudig online doen.",
		button: "Bekijk abonnementen",
		getjobsdone: {
			title: "Je hebt nog geen actief abonnement",
			description:
				"Zet vandaag nog de stap naar meer gedaan krijgen met GetJobsDone! Activeer eenvoudig online je abonnement en krijg direct toegang tot {{global.students}} (Young) Professionals.",
			button: "Bekijk abonnementen",
			usps: {
				first: "Direct toegang tot {{global.students}} (Young) Professionals",
				second: "Opdrachten uitbesteden voor een vast bedrag",
				third: "Betaling en overeenkomsten online geregeld",
			},
		},
	},
	en: {},
};

export default withTranslation(NoSubscriptionMiddleware);
