import { ComponentProps, createContext, useContext } from "react";
import { ApiListTenant, ApiTenant } from "src/api/types";
import { useT } from "src/components/hoc/withTranslation";
import Logo from "src/components/Logo";
import useTenants from "src/hooks/api/services/tenants/useTenants";
import useAuth from "src/hooks/selectors/useAuth";
import { cn } from "src/lib/utils";

type TenantContextType = {
	tenant: ApiListTenant;
};

const TenantContext = createContext<TenantContextType>({} as TenantContextType);

interface TenantProps {
	id: ApiTenant["slug"];
	children: React.ReactNode;
}
export default function Tenant({ id, children }: TenantProps) {
	const { tenants } = useTenants();

	const tenant = tenants.find((tenant) => tenant.slug === id);

	if (!tenant) {
		return null;
	}

	return (
		<TenantContext.Provider value={{ tenant }}>
			{children}
		</TenantContext.Provider>
	);
}

export function TenantName({
	className,
	...rest
}: Omit<ComponentProps<"span">, "children">) {
	const { tenant } = useContext(TenantContext);
	return (
		<span className={cn(className)} {...rest}>
			{tenant.name}
		</span>
	);
}

export function TenantDescription({
	className,
	...rest
}: Omit<ComponentProps<"span">, "children">) {
	const { tenant } = useContext(TenantContext);
	const auth = useAuth();
	const t = useT({
		nl: {
			getcrewdone: {
				student: "Werk op de dikste festivals",
				company: "Schakel jongeren in voor jouw event",
			},
			getjobsdone: {
				student: "Doe opdrachten die passen bij je skills",
				company: "Schakel (Young) Professionals in voor jouw bedrijf",
			},
			getrecruitmentdone: {
				student: "Vind jouw volgende job",
				company: "Vind (Young) Professionals voor al je vacatures",
			},
			getstagedone: {
				student: "Vind jouw volgende stage",
				company: "Vind (Young) Professionals voor je stageplekken",
			},
		},
		en: {
			getcrewdone: {
				student: "Work at the biggest festivals",
				company: "Hire young people for your event",
			},
			getjobsdone: {
				student: "Do assignments that match your skills",
				company: "Hire (Young) Professionals for your company",
			},
			getrecruitmentdone: {
				student: "Find your next job",
				company: "Find (Young) Professionals for all your vacancies",
			},
		},
	});

	return (
		<span className={cn(className)} {...rest}>
			{t(`${tenant.slug}.${auth?.type || "company"}`)}
		</span>
	);
}

export function TenantLogo({
	...rest
}: Omit<ComponentProps<typeof Logo>, "slug">) {
	const { tenant } = useContext(TenantContext);
	return <Logo {...rest} slug={tenant.slug} />;
}
