import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb, { Crumb } from "src/components/Breadcrumb";
import Button from "src/components/Button";
import Link from "src/components/Link";
import Logo from "src/components/Logo";
import AlertButton from "src/components/alerts/AlertButton";
import Container, { ContainerVariant } from "src/components/layout/Container";
import ToggleButton from "src/components/layout/ToggleButton";
import UserProfileButton from "src/components/layout/UserProfileButton";
import LayoutDesktopDragSpacer from "src/components/layout/layout-default/LayoutDesktopDragSpacer";
import NavItems from "src/components/layout/layout-default/sidebar/NavItems";
import CreateTaskButton from "src/components/tasks/TaskForm/elements/CreateTaskButton";
import Timer from "src/components/time-tracker/Timer";
import Text from "src/components/ui/text";
import useTenant from "src/hooks/api/services/tenants/useTenant";
import useTimeTracking from "src/hooks/api/services/useTimeTracking";
import { ParsedBackUrl, useParseBackUrl } from "src/hooks/useBackUrl";
import useElementSize from "src/hooks/useElementSize";
import useLayout from "src/hooks/useLayout";
import useTheme from "src/hooks/useTheme";
import useWindowSize from "src/hooks/useWindowSize";
import { cn } from "src/lib/utils";
import SearchButton from "../SearchButton";

interface LayoutHeadProps {
	className?: string;
	actions?: any;
	title?: string;
	titleActions?: JSX.Element;
	subtitle?: string;
	backUrl?: ParsedBackUrl;
	variant: "default" | "small" | "no-container";
	// animation?: keyof typeof animationVariants;
	animation?: string;
	breadcrumb?: JSX.Element | Crumb[];
	children?: any;
	container?: ContainerVariant;
}

const LayoutHead = ({
	title,
	subtitle,
	className,
	actions,
	backUrl,
	variant,
	breadcrumb,
	children,
	container,
}: LayoutHeadProps) => {
	const { actions: layoutActions } = useLayout();
	const { taskType } = useTenant();
	const [visible, setVisible] = useState(false);
	const location = useLocation();
	const { isPhone, isTablet, breakpoint } = useWindowSize();
	const isDesktop = !(isPhone || isTablet);
	const { ref, size } = useElementSize();
	const _backUrl = useParseBackUrl();
	const { theme } = useTheme();
	const { time: timeTracking } = useTimeTracking();
	const isShowbutton = isDesktop && (_backUrl || backUrl);
	const hasTitleBar = children || title || subtitle;

	useEffect(() => {
		layoutActions.setHead({
			size,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [size]);

	useEffect(() => {
		setVisible(false);
	}, [location]);

	return (
		<>
			<LayoutDesktopDragSpacer />
			<motion.div
				initial={false}
				{...{ ref }}
				style={{
					zIndex: 93,
				}}
				className={cn("sticky top-0 py-3", className)}
			>
				<div className="absolute bg-gray-background opacity-80 pointer-events-none dark:bg-background inset-0 "></div>
				<Container
					{...{ variant }}
					className="flex items-center justify-between z-10 relative"
				>
					<div className="flex items-center gap-4">
						{!isDesktop && (
							<Link to="/dashboard">
								<Logo
									color={
										theme === "dark" ? "primary" : "dark"
									}
									variant="small"
									className="w-8 h-8 block"
								/>
							</Link>
						)}
						{isShowbutton && (
							<Button
								type="accent"
								iconOnly
								icon="far fa-arrow-left"
								className="w-10 h-10"
								to={_backUrl || backUrl}
							/>
						)}

						{breadcrumb && (
							<div className="items-center hidden md:flex">
								{React.isValidElement(breadcrumb) ? (
									breadcrumb
								) : (
									<Breadcrumb routes={breadcrumb as any} />
								)}
							</div>
						)}
					</div>
					<div className="flex items-center gap-3">
						{!hasTitleBar && (
							<div className="items-center gap-3 hidden lg:flex">
								{actions}
							</div>
						)}

						{/* {taskType === "extern" && !subscription?.id && (
							<SubscribeExternButton className="mr-3" />
						)} */}

						{taskType === "extern" &&
							!location?.pathname?.startsWith("/tasks/") && (
								<CreateTaskButton
									small
									variant="CREATE_NEW"
									iconOnly={["xs", "sm", "md"].includes(
										breakpoint
									)}
								/>
							)}

						{timeTracking?.id && (
							<Timer options={{ showOriginTooltip: true }} />
						)}
						<SearchButton />
						<AlertButton />
						<UserProfileButton />
						<div className="flex lg:hidden ml-2">
							<ToggleButton
								open={visible}
								stroke={
									theme === "dark" ? "#ffffff" : "#18383B"
								}
								onClick={() => setVisible(!visible)}
							/>
						</div>
					</div>
				</Container>
			</motion.div>
			{hasTitleBar && (
				<Container
					variant={container || variant}
					className={cn("mt-4 mb-2 md:mb-4", children && "mb-4")}
				>
					{(title || subtitle) && (
						<div
							className={cn(
								"flex justify-between items-center",
								children && "mb-4"
							)}
						>
							<div className="flex flex-col text-background-foreground">
								<div className={cn("flex")}>
									{typeof title === "string" ? (
										<Text.Title>{title}</Text.Title>
									) : (
										title
									)}
								</div>
								{typeof subtitle === "string" ? (
									<small className="hidden sm:flex">
										{subtitle}
									</small>
								) : (
									subtitle
								)}
							</div>
							{actions && (
								<div className="flex items-center gap-3">
									{actions}
								</div>
							)}
						</div>
					)}
					{children}
				</Container>
			)}
			<AnimatePresence>
				{visible && (
					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						className="fixed inset-0 bg-background z-[80] flex flex-col flex-1"
						style={{
							paddingTop: size.top + size.height,
						}}
					>
						<div className="pb-8 overflow-auto flex-1 flex flex-col">
							<NavItems />
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
};

LayoutHead.defaultProps = {
	variant: "default",
};

export default LayoutHead;
