import { useEffect, useMemo, useRef, useState } from "react";
import ScrollView from "react-inverted-scrollview";
import Button from "src/components/Button";
import Card from "src/components/Card";
import withTranslation, {
	Translation,
} from "src/components/hoc/withTranslation";
import Examples from "src/components/tasks/create-task-chat/Examples";
import Final, {
	FormattedTaskChat,
} from "src/components/tasks/create-task-chat/Final";
import Message from "src/components/tasks/create-task-chat/Message";
import useAuth from "src/hooks/selectors/useAuth";
import useElementSize from "src/hooks/useElementSize";
import { cn } from "src/lib/utils";

export type MessageType = {
	key: string;
	variant: "USER" | "SYSTEM";
	value: string;
	loading: boolean;
};

type ExtraKey = "BUDGET";

interface CreateTaskChatProps extends Translation {
	className?: string;
	contentClassName?: string;
	onSubmit: (formatted: FormattedTaskChat) => void;
	onChange?: (formatted: FormattedTaskChat) => void;
	isLoading?: boolean;
	children?: any;
	autoSubmit?: boolean;
	extra?: ExtraKey[];
	noMaxWidth?: boolean;
}

const CreateTaskChat = ({
	t,
	className,
	onSubmit: onCreateSubmit,
	onChange,
	isLoading,
	contentClassName,
	children,
	autoSubmit,
	extra = [],
	noMaxWidth,
}: CreateTaskChatProps) => {
	const chatBox = useElementSize();
	const chatBoxInnerRef = useRef<any>();
	const inputRef = useRef<any>();
	const auth = useAuth();
	const [message, setMessage] = useState<string>("");
	const [messages, setMessages] = useState<MessageType[]>([
		{
			key: "TITLE",
			variant: "SYSTEM",
			loading: false,
			value: !auth?.id
				? t("messages.START_WITHOUT_AUTH")
				: t("messages.START", {
						name: auth?.company?.name,
				  }),
		},
	]);
	const keys: string[] = useMemo(() => {
		return [...["TITLE", "GOAL", "TASKS", "DELIVER"], ...extra];
	}, [extra]);
	const lastMessage = messages[messages.length - 1];
	const currentKey = lastMessage?.key;
	const isLastAnswered =
		currentKey === keys[keys.length - 1] && lastMessage?.variant === "USER";
	const isLastLoading = messages[messages.length - 1]?.loading;
	const getValue = (key: string, msgs: MessageType[]) => {
		return (
			msgs.find((m) => m.key === key && m.variant === "USER")?.value || ""
		);
	};
	const isNumericInput = ["BUDGET"].includes(currentKey);
	const getFormatted = (msgs: MessageType[]) => {
		return {
			title: getValue("TITLE", msgs),
			budget: getValue("BUDGET", msgs),
			answers: [
				{
					question: "What is the goal of the task?",
					answer: getValue("GOAL", msgs),
				},
				{
					question:
						"What kind of tasks are covered by the assignment?",
					answer: getValue("TASKS", msgs),
				},
				{
					question: "How should the task be delivered?",
					answer: getValue("DELIVER", msgs),
				},
			],
		};
	};

	useEffect(() => {
		if (onChange) {
			onChange(getFormatted(messages));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [messages]);

	const handleCreateMessage = (msg: string) => {
		if (
			!msg ||
			isLastLoading ||
			messages[messages.length - 1].variant === "USER"
		)
			return;

		// const key = "TITLE";
		const newMessages: MessageType[] = [
			...messages,
			{ key: currentKey, variant: "USER", value: msg, loading: false },
		];
		setMessages(newMessages);
		inputRef.current.focus();
		chatBoxInnerRef.current.scrollToBottom();

		setMessage("");
		const lastMessage = messages[messages.length - 1];
		const lastCurrentKey = lastMessage?.key;
		const lastCurrentKeyIndex = keys.indexOf(currentKey);

		//Last message
		if (lastCurrentKey === keys[keys.length - 1]) {
			chatBoxInnerRef.current.scrollToBottom();
			if (autoSubmit) {
				onCreateSubmit(getFormatted(newMessages));
			}
			return;
		} else {
			//Add the new message
			const nextKey = keys[lastCurrentKeyIndex + 1];
			setMessages((state) => [
				...state,
				{
					key: nextKey,
					variant: "SYSTEM",
					loading: true,
					value: t(`messages.${nextKey}`, {
						name: auth?.company?.name,
					}),
				},
			]);
			chatBoxInnerRef.current.scrollToBottom();
			setTimeout(() => {
				setMessages((state) =>
					state.map((item) => ({
						...item,
						loading: false,
					}))
				);
			}, 800);
		}
	};

	const onSubmit = (event?: any) => {
		if (event) {
			event.preventDefault();
		}
		handleCreateMessage(message);
	};

	return (
		<Card
			className={cn("flex-1", className)}
			title={t("title")}
			contentClassName={cn(contentClassName)}
		>
			<div
				className={cn(
					"flex flex-col gap-3 flex-1 relative",
					!noMaxWidth && "max-w-xl"
				)}
			>
				<div ref={chatBox.ref} className="flex flex-col flex-1">
					<ScrollView
						ref={chatBoxInnerRef}
						width={chatBox.size.width}
						height={chatBox.size.height}
					>
						<div className="flex flex-col gap-4">
							{messages.map((message, index) => (
								<Message
									{...message}
									key={`message-${index}`}
								/>
							))}
							{isLastAnswered && !children && !autoSubmit && (
								<Final
									{...{ messages, isLoading }}
									onSubmit={() => {
										onCreateSubmit(getFormatted(messages));
									}}
									onScrollToBottom={() =>
										chatBoxInnerRef.current.scrollToBottom()
									}
								/>
							)}
							{children}
						</div>
					</ScrollView>
				</div>
				{currentKey === "TITLE" && (
					<Examples onSelect={handleCreateMessage} />
				)}
				{!isLastAnswered && (
					<form
						className="flex w-full gap-2 items-center rounded-md"
						{...{ onSubmit }}
					>
						<input
							ref={inputRef}
							type={isNumericInput ? "number" : "text"}
							className="p-2 pl-4 bg-accent rounded-md flex-1 h-[47px]"
							value={message}
							onChange={(event) =>
								setMessage(event?.target?.value)
							}
							placeholder={t("placeholder")}
						/>
						<Button submit>{t("send")}</Button>
					</form>
				)}
			</div>
		</Card>
	);
};

CreateTaskChat.locale = {
	nl: {
		title: "Maak je opdracht",
		messages: {
			START: "Hi! Hoe kunnen onze {{global.students}} (young) professionals {{name}} helpen? Geef enkel een opdracht titel op.",
			START_WITHOUT_AUTH:
				"Hi! Hoe kunnen onze {{global.students}} (young) professionals jou helpen? Geef enkel een opdracht titel op.",
			GOAL: "Wat is het doel van de opdracht?",
			TASKS: "Wat voor taken vallen er onder de opdracht?",
			DELIVER: "Hoe moet de opdracht opgeleverd worden?",
			BUDGET: "Hoeveel zou je de opdrachtnemer willen betalen? Geef alleen het bedrag",
		},
		send: "Versturen",
		placeholder: "Stuur een bericht",
	},
	en: {
		title: "Create your task",
		messages: {
			START: "Hi! How can our {{global.students}} (young) professionals help {{name}}? Just provide a project title.",
			START_WITHOUT_AUTH:
				"Hi! How can our {{global.students}} (young) professionals help you? Just provide a project title.",
			GOAL: "What is the goal of the project?",
			TASKS: "What tasks are involved in the project?",
			DELIVER: "How should the project be delivered?",
			BUDGET: "How much would you like to pay the contractor? Please only provide a number",
		},
		send: "Send",
		placeholder: "Message {{tenant.code}}",
	},
};

export default withTranslation(CreateTaskChat);
